// IMPORTS
@import (once) "/node_modules/nouislider/dist/nouislider.css";

@import (reference) "../settings.less";

// FILTER
#filter,
#pills-container,
.filter-trigger {
    display: none !important;
}

// LISTING WRAPPER
.listing-wrapper {
    .grid();
    grid-template-columns: minmax(0, 1fr);
    gap: 40px;
}

// CATEGORY DESCRIPTION
.category-header-wrapper {
    margin-bottom: -5px;
    background-color: @light-bg;
    text-align: center;
    padding: 48px 140px;

    &:not(:has(> *)) {
        display: none;
    }

    .category-headline {
        font-size: 32px;

        &:only-child {
            margin-bottom: 0 !important;
        }
    }

    .category-description {
        color: @font-color;
    }

    article {

        img {
            margin: 16px;
            display: block;
        }
    }

}

// NAVIGATION
.navigation-wrapper {
    display: none;
    flex-wrap: wrap;
    gap: 17px;

    &:has(#pills-container .single-pill),
    &:has(.dropdown-picker) {
        display: flex;
    }
}

.listing-wrapper:has(.products-list:empty) {

    .navigation-wrapper {
        display: none;
    }

    .dropdown-picker {
        display: none;
    }
}

// DROPDOWN PICKER
.dropdown-picker {
    position: relative;
    display: flex;
    position: relative;
    z-index: 10;

    &.show-dropdown {

        .dropdown-button:after {
            transform: scaleY(-1);
        }

        .dropdown-listing {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }

    &:before {
        background-color: white;
        position: absolute;
        top: -12px;
        left: 16px;
        font-size: 12px;
        line-height: 1.2;
        letter-spacing: 0.05em;
        font-weight: 500;
        text-transform: uppercase;
        font-family: @sec-font-family;
        color: @header-color;
        padding: 4px 8px;
        z-index: 11;
        pointer-events: none;
    }

    &[data-dropdown-type="sort"]:before {
        content: 'Sortowanie';
    }

    &[data-dropdown-type="pageSize"] {

        &:before {
            content: 'Ilość na stronie';
        }

        .dropdown-button {
            min-width: 200px;
        }
    }
}

.dropdown-button {
    border: 1px solid @border-color;
    color: @font-color;
    min-width: 280px;
    height: 48px;
    text-align: left;
    position: relative;
    padding: 0 44px 0 24px;
    z-index: 1;

    &:after {
        content: "\e911";
        font-family: icomoon;
        position: absolute;
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;
        color: @header-color;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media(min-width: 1280px) {
        &:hover {
            border-color: @header-color;
        }
    }
}

.dropdown-listing {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid @border-color;
    border-top: 0;
    .shadow();
    min-width: 100%;
    padding: 6px 0;
    transform: translateY(-5px);
    transition: all .2s ease-in-out;

    span {
        display: block;
    }

    input {
        display: none;

        &:checked + label {
            font-weight: 700;
            color: @header-color;
            text-decoration: none !important
        }
    }

    label {
        display: block;
        padding: 6px 24px;
        user-select: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

@media (max-width: 600px) {
    .category-header-wrapper {
        display: contents;
    }

    .category-description {
        order: 2;
    }
}

// IE FALLBACK
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .sorter-button:before {
        display: none;
    }
}